import { Component, OnInit, PLATFORM_ID, Inject, AfterViewChecked } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { UserService } from 'src/app/services/user.service';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { LangService } from 'src/app/services/lang.service';
import { HomeService } from 'src/app/services/home.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('itemAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})

export class HomeComponent implements OnInit, AfterViewChecked {

  showSidebar: boolean = true;
  logged: boolean = false;
  isBrowser: boolean;
  roleName: string = "";

  toSliceCompany = 4;
  fromSliceCompany = 0;
  arrayProduct: number[] = [1, 2, 3, 4, 5, 6, 7, 8];

  environment = environment;
  featured: any[];
  featured2: any[];
  featuredGrid: any[];
  featuredGridTitle: string = '';
  featuredGridTwo: any[];
  featuredGridTwoTitle: string = '';
  product: any = {}
  enterprices: any[] = [];
  sessionLang: string;
  browserLang: string;
  lang: string = '';
  allowLangs: string[] = [
    'es',
    'en',
    'pt',
    'zh'
  ];
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    nav: false,
    navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
    autoplay: false,
    margin: 0,
    items: 1,
    responsive: {
      0: {
        items: 1
      },
      480: {
        items: 2
      },
      768: {
        items: 3
      },
      970: {
        items: 3,
        dots: false,
        nav: true,
        margin: 20
      },
      1100: {
        items: 4,
        dots: false,
        nav: true,
        margin: 30
      }
    }
  }
  categoryOptions;
  en = {
    'home1': 'https://www.youtube.com/embed/ryr2-_dGdVw?si=ClVnf5qRno6JSeD4',
    'Cómo registrar Usuario': 'https://www.youtube.com/embed/BIK9669ScDA?si=cpU_ouPCQO5Y6ocd',
    'Como registrar Tienda': 'https://www.youtube.com/embed/J2NsUmqsL5Q?si=T4FIr4Nq8P_wlZzM',
    'Cómo Comprar en eDairy Market': 'https://www.youtube.com/embed/_yK32ODNmfg?si=agkbGxyfBIhvkC5H',
    'Cómo Vender en eDairy Market': 'https://www.youtube.com/embed/ryr2-_dGdVw?si=IPcaDB30yg6FYPS_'
  }

  es = {
    'home1': 'https://www.youtube.com/embed/pbQnul1BpcA?si=PCGR8hAZA-lz5Ysy',
    'Cómo registrar Usuario': 'https://www.youtube.com/embed/8sPA4acG7E4?si=BWFMKDasJE48J40j',
    'Como registrar Tienda': 'https://www.youtube.com/embed/MD5Tpr40FXY?si=7JB8qboK_r3b2wse',
    'Cómo Comprar en eDairy Market': 'https://www.youtube.com/embed/SDnEJUBkda4?si=CPiafuVUHbxBxD1M',
    'Cómo Vender en eDairy Market': 'https://www.youtube.com/embed/pbQnul1BpcA?si=8eCg265gYs691x5h'
  }

  pt = {
    'home1': 'https://www.youtube.com/embed/-D20zFy1Fj8?si=nSaRNax1W4TVRFy5',
    'Cómo registrar Usuario': 'https://www.youtube.com/embed/FpQPu3b_0uM?si=3ZWxu62zX2rEvI10',
    'Como registrar Tienda': 'https://www.youtube.com/embed/nHy8YneNTBE?si=6146R9uegjZADwbJ',
    'Cómo Comprar en eDairy Market': 'https://www.youtube.com/embed/FpQPu3b_0uM?si=2aBpb2Qpo9l9xovX',
    'Cómo Vender en eDairy Market': 'https://www.youtube.com/embed/-D20zFy1Fj8?si=PeWjTMFWEPN-Zwvl'
  }
  
  zh = {
    'home1': 'https://www.youtube.com/embed/ryr2-_dGdVw?si=ClVnf5qRno6JSeD4',
    'Cómo registrar Usuario': 'https://www.youtube.com/embed/BIK9669ScDA?si=cpU_ouPCQO5Y6ocd',
    'Como registrar Tienda': 'https://www.youtube.com/embed/J2NsUmqsL5Q?si=T4FIr4Nq8P_wlZzM',
    'Cómo Comprar en eDairy Market': 'https://www.youtube.com/embed/_yK32ODNmfg?si=agkbGxyfBIhvkC5H',
    'Cómo Vender en eDairy Market': 'https://www.youtube.com/embed/ryr2-_dGdVw?si=IPcaDB30yg6FYPS_'
  }
  
  videos;

  constructor(
    private translate: TranslateService,
    private _lang: LangService,
    private _home: HomeService,
    @Inject(PLATFORM_ID) platformId: Object,
    private titleService: Title,
    private metaService: Meta,
    private _auth: AuthService,
    private _sidebar: SidebarService,
    private router: Router,
    private _user: UserService) {

    this.lang = this._lang.getLanguage('edm_lang')
    translate.use(this.lang);

    _auth.isLogged.subscribe(logged => this.logged = logged);
    this._user.roleNameEvent.subscribe(roleName => this.roleName = roleName);
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.browserLang = navigator.language.toLowerCase().split('-')[0];
      this.browserLang = this.allowLangs.find(lang => lang == this.browserLang);
      if (!this.browserLang) {
        this.browserLang = 'en';
      }
    }
    this.videos = this[this.lang];
    console.log(this.videos);
  }

  ngAfterViewChecked(): void {
    let images = document.querySelectorAll(".product .main-image")
    if (images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        let parentHeight = images[i].parentElement.clientHeight
        let imageHeight = images[i].clientHeight
        let marginTB = imageHeight < parentHeight && imageHeight > 0 ? (parentHeight - imageHeight) / 2 : 0
        images[i].setAttribute("style", `margin-top:${marginTB}px;margin-bottom:${marginTB}px;`)
      }
    }
  }

  ngOnInit() {

    this._sidebar.hideSidebarGlobal();
    this.sessionLang = this._lang.getLanguage('edm_lang')

    this.translate.get([
      "HOME.METATITLE",
      "HOME.METADESCRIPTION",
      "HOME.META_KEYWORD"
    ]).subscribe(tags => {
      if (tags["HOME.METATITLE"]) {
        this.titleService.setTitle(tags["HOME.METATITLE"])
      }

      if (tags["HOME.METADESCRIPTION"]) {
        this.metaService.updateTag(
          { name: 'description', content: tags["HOME.METADESCRIPTION"] }
        );
      }

      if (tags["HOME.META_KEYWORD"]) {
        this.metaService.updateTag(
          { name: 'keywords', content: tags["HOME.META_KEYWORD"] }
        );
      }

    })

    this._home.getHome(this.sessionLang).subscribe((h: any) => {
      console.log(h)
      this.featured = h.featured;
      this.featured2 = h.featured2;

      this.featuredGrid = h.section.products;
      this.featuredGridTitle = h.section.name;
      this.featuredGridTwo = h.section2.products;
      this.featuredGridTwoTitle = h.section2.name;
//      this.product = h.featured2;
//      console.log('prod', this.product);

      this.enterprices = h.enterprices;
    })

    this.categoryOptions = [
      {
        label: 'HOME.HOME_BODY_CATEGORIES_POPULAR_TEXT_PRODUCTS',
        iconPath: 'assets/img/home/lacteos-icon.svg',
        link: '/products/products'
      },
      {
        label: 'HOME.HOME_BODY_CATEGORIES_POPULAR_TEXT_MACHINES',
        iconPath: 'assets/img/home/machinery.svg',
        link: '/products/machinery'
      },
      {
        label: 'HOME.HOME_BODY_CATEGORIES_POPULAR_TEXT_INGREDIENTS',
        iconPath: 'assets/img/home/ingredientes.svg',
        link: 'products/ingredients'

      },
      {
        label: 'HOME.HOME_BODY_CATEGORIES_POPULAR_TEXT_VEGETABLE_ALTERNATIVES',
        iconPath: 'assets/img/home/sust_lacteos-icon.png',
        link: '/products/substitutes-dairy'
      },
      {
        label: 'HOME.HOME_BODY_CATEGORIES_POPULAR_TEXT_SERVICES',
        iconPath: 'assets/img/home/services.png',
        link: '/products/services'
      },
      {
        label: 'HOME.HOME_BODY_CATEGORIES_POPULAR_TEXT_NUTRITION',
        iconPath: 'assets/img/home/animal-icon.png',
        link: '/products/animal-nutrition'
      }
    ]

  }
  
  goShop (shop) {
      this.router.navigate(['store/' + shop]);
  }

  getPrice(value: string) {
    return value === 'A Convenir' ? value : `$${value}`;
  }

  getProducts() {
    return [
      ... this.featuredGrid,
      ... this.featured2,
      ... this.featured2,
      ... this.featured
    ]
  }

  navigateNews() {
    window.open("https://edairynews.com/", '_blank');
  }

  openProductInNewTab(productId: string) {
    window.open(`/products/id/${productId}`)
  }

  nextCompany() {
    this.fromSliceCompany++;
    this.toSliceCompany++;
  }

}
